import { render, staticRenderFns } from "./Programari_viitoare.vue?vue&type=template&id=d541bcf6&scoped=true&"
import script from "./Programari_viitoare.vue?vue&type=script&lang=js&"
export * from "./Programari_viitoare.vue?vue&type=script&lang=js&"
import style0 from "./Programari_viitoare.vue?vue&type=style&index=0&id=d541bcf6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d541bcf6",
  null
  
)

export default component.exports