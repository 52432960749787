<template id="istoric">
    <div class="istoric">
      <div class="content">
        <el-card class="box-card">      
          <el-collapse v-model="active" accordion style="border: none">
            <el-collapse-item name="1" style="border: none">
              <template slot="title">
                <div slot="header" class="clearfix header">
                  <span class="title">{{Titlu}}</span>
                </div>
              </template>
                <div style="padding:4px;">
                  <el-form @submit.prevent.native="refresh_info()" label-position="top">
                    <el-row :gutter="15" class="input-group">
                      <el-col :md="6" :sm="24" class="input-element">
                        <el-form-item label="Perioada" class='full-width'>
                            <el-date-picker
                              v-model="Filters.Perioada"
                              @change="handleDateChange"
                              type="daterange"
                              :picker-options="datePickerOptions"
                              range-separator="-"
                              start-placeholder="Data inceput"
                              end-placeholder="Data sfarsit"
                              size="small"
                              format="dd/MM/yyyy"
                              value-format="yyyy-MM-dd"
                              style="width:100%"
                              clearable/>
                        </el-form-item>
                      </el-col> 
                      <el-col class="input-element" :md="6" :sm="24">
                        <el-form-item label='Client' >
                            <el-select style="width:100%" v-model='Filters.IdClient' clearable filterable @change="refresh_info" size="small">
                                <el-option v-for='item in Info.Clienti' :key="'client' + item.Id" :label="item.Nume + ' ' + item.Prenume" :value='item.Id'/>
                            </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col class="input-element" :md="6" :sm="24">
                        <el-form-item label='Masina' >
                            <el-select style="width:100%" v-model='Filters.IdMasina' clearable filterable @change="refresh_info" size="small">
                                <el-option v-for='item in Info.Masini' :key="'masina' + item.Id" :label="item.Numar" :value='item.Id'/>
                            </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :sm="1">
                        <el-form-item label="Export">
                          <el-button @click="exportHandler"  icon="el-icon-s-promotion" size="small" type="info" circle></el-button>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
            </el-collapse-item>
          </el-collapse>
          <el-table
            width="1000px"
            style="width:100%;height:450px;overflow-y: scroll"
            :data="Programari"
            size=small
          >
          <el-table-column label="Id rezervare">
            <template slot-scope="scope">
              # {{ scope.row.Id }}
            </template>
          </el-table-column>
          <el-table-column   prop="NumeCentruProductie" label="Centru productie"></el-table-column>
          <el-table-column   prop="DataRezervarii" label="Data rezervarii"></el-table-column>
          <el-table-column   prop="DurataMin" label="Durata(min)"></el-table-column>
          <el-table-column   label="Mecanic">
            <template slot-scope="scope">
              {{ scope.row.NumeMecanic ? scope.row.NumeMecanic : '-' }}
            </template>
          </el-table-column>
          <el-table-column   label="Client">
            <template slot-scope="scope">
              {{ scope.row.NumeClient ? scope.row.NumeClient : '-' }}
            </template>
          </el-table-column>
          <el-table-column   label="Masina">
            <template slot-scope="scope">
              {{ scope.row.Masina ? scope.row.Masina : '-' }}
            </template>
          </el-table-column>
          <el-table-column   prop="Cantitate" label="Cantitate(roti)"></el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>
  </template>
  
  <script>
    import settings from '../backend/LocalSettings.js'
    import moment from "moment";
    import TitluPagina from "@/widgets/TitluPagina";
  
  export default {
    name: "Programari_viitoare",
    components:{
      'titlu-pagina':TitluPagina
    },
    data() {
      return {
        Filters:{Perioada:[],IdClient:'',IdMasina:''},
        Info:{Masini:[],Clienti:[]},
        selectedObject: {},
        Programari: [],
        datePickerOptions:{},
        Titlu:'',
        active:'1',
      };
    },
  
    methods: {
      async exportHandler(){
        try{
          const export_excel = "https://service.anvelopeinstoc.ro/api/index.php/test_api/export_excel_programari"; // /api pt dev
          const args = { secure_email: this.secure_email, filtre: this.Filters,CheckSender:settings.get_key()};
          const response = await this.$http.post(export_excel, args,{
              headers: {
                  'Content-Type': 'application/json'
              }
          });
          const blob = new Blob([response.body], { type: 'application/vnd.ms-excel' });
  
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "Export programari viitoare.xls";
  
          document.body.appendChild(link);
          link.click();
  
          document.body.removeChild(link);
              
        }catch(error){
          console.error(error)
        }
      },
  
      refresh_info: async function () {
        this.selectedObject = {
          Programari: [],
        };
        this.secure_email = this.$route.params.secure_email;
        let api_programari = "https://service.anvelopeinstoc.ro/api/index.php/test_api/get_programari_viitoare"; // /api pt dev
        let args = { secure_email: this.secure_email, CheckSender:settings.get_key(), filtre: this.Filters };
        let response = await this.$http.post(api_programari, args);
        let result = response.body.result;
        
        this.Programari = result;
        this.Titlu = response.body.Titlu;
          
      },
      handleDateChange(dates) {
        if (dates && dates.length === 2) {
          const [start, end] = dates;
  
          this.convertStartEndHours(start,end)
          this.refresh_info();
        }else if(!dates || dates.length === 0){
          this.refresh_info();
        }
      },
      convertStartEndHours(start,end){
        const startDate = new Date(start);
        const endDate = new Date(end);
  
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
  
        this.Filters.Perioada = [
          this.formatDateForValue(startDate),
          this.formatDateForValue(endDate)
        ];

      },
      formatDateForValue(date) {
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');
  
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
      async get_info(){
        try{
          let api_programari = "https://service.anvelopeinstoc.ro/api/index.php/test_api/get_info_programari"; // /api pt dev
          let response = await this.$http.post(api_programari);

          this.Info.Masini = response.body['Masini'];
          this.Info.Clienti = response.body['Clienti'];

          await this.refresh_info();
        }catch(error){
          console.error(error)
        }
      }
    },
    mounted() {
      this.Filters = {Perioada:[],IdMasina:'',IdClient:''};
      this.Info = {Masini:[],Clienti:[]};
      this.Titlu = '';
      this.active = '1';

      this.convertStartEndHours(moment().startOf('month').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD'))
      this.datePickerOptions = settings.datePickerOptions;
      
      this.get_info();
    },
  };
  </script>
  
  
  <style lang="less" scoped>
      @gri: #F1F2F2;
      @gri_inchis: #8e97b3;
      @albastru_transparent:rgba(76, 100, 232, 0.445);
      @albastru: #4C63E8;
      @verde: #4C63E8;
      @rosu-hover: #F7E8E8;
      @rosu: #ec7376;
      @rosu_deschis: #F7E8E8;
      @galben: #FEF6CB;
      @galben_wallpaper: #FCFEF1;
      @galben_inchis: #E1D588;
      @liber: #caffc4;
      @liber_text: #185210;
      @ocupat: #f3f5f9;
      @ocupat_text: #b3bbca;
      @zi_indisponibila: #f7e9e9;
      @zi_indisponibila_text: #752f2f;
      .ok {
          width: 30px;
          height: 30px;
          margin: 0;
          padding: 3px;
          text-align: center;
          border-radius: 50%;
          background-color: @albastru;
          color: white;
      }
      .eroare {
          width: 30px;
          height: 30px;
          margin: 0;
          padding: 3px;
          text-align: center;
          border-radius: 50%;
          background-color: @rosu;
          color: white;
      }
  
  
      .istoric {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
      }
        .content {
          width: 85%;
          height: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          border-radius: 5px;
          background: transparent;
          // box-shadow: 0px 2px 4px #00000033, 0px 20px 50px #00000066;
      }
  
  .title {
      color: @albastru;
      font-size: 24px;
      font-weight: 500;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
  }
  
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .box-card {
    width: 95%;
    height: 90%;
    overflow: hidden;
    // overflow-y: scroll;
    .el-table {
      overflow-y: scroll !important;
    }
  }
  </style>